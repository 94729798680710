export default function Footer () {
  return (
      <footer className='p-2 text-center sticky-bottom position-relative' id='learnaboutus'>
        <div className=' container '>
          <p> Made with ❤️ by TeamSoc
          </p>
        </div>
      </footer>
  )
}
